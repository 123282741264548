* {
    margin: 0;
    padding: 0;
}

.ant-layout-footer {
    width: 100%;
    height: 60px;
    padding: 0px 10px;
    color: #c0c0c0 !important;
    font-size: 14px;
    text-align: center;
    background: #001529 !important;
    position: relative;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;
    div {
        word-wrap: break-word;
        word-break: normal;
        a {
            color: #939393;
        }
    }
    .beianContainer {
        display: flex;
        align-items: center;
        .beian {
            width: 300px;
            margin: 0 auto;
            height: 20px;
            a {
                display: inline-block;
                text-decoration: none;
                height: 20px;
                line-height: 20px;
                img {
                    float: left;
                }
                p {
                    float: left;
                    height: 20px;
                    line-height: 20px;
                    margin: 0px 0px 0px 5px;
                    color: #939393;
                }
            }
        }
    }
}
